<template>
  <b-overlay
    :show="isLoading"
    :opacity="1"
  >
    <component
      :is="optionsToUse.shouldUseCard ? 'b-card' : 'div'"
      no-body
      :class="componentClasses"
    >
      <!-- On HSBC Env, we want to show the tabs when we are logged in to HSBC UAT as admin-->
      <!-- On other Env, we show the tabs only if there is more than one tab to show -->
      <div
        v-if="tabsToShow.length > 1 || shouldShowPortfolioTabHSBC"
        class="my-2"
      >
        <!-- Hide all tabs when its in equity basket page as there would only be equity basket tab -->
        <b-tabs
          :value="currentTabIndex"
          pills
          nav-class="d-flex justify-content-around"
          style="min-height: 37px"
        >
          <!-- custom min-height added so the panel does not jump when
              the tab data is loaded -->
          <b-tab
            v-for="t in tabsToShow"
            :key="t"
            :title="tabNames[t]"
            @click="selectSection(t)"
          />
        </b-tabs>
      </div>
      <div>
        <b-card
          no-body
          class="rounded-0 border-right-0 border-left-0"
          bg-variant="light"
        >
          <div :style="headerRowStyle">
            <div class="d-flex align-items-center">
              <b-form-checkbox
                v-if="optionsToUse.selectMultiple"
                v-model="areMaxStrategiesSelected"
                :indeterminate.sync="areSomeStrategiesSelected"
                class="ml-2"
                style="margin-top: 1px"
                data-testid="select-all-checkbox"
                @change="toggleAllSelected()"
              />
            </div>
            <div
              id="dsbNameColumn"
              class="d-flex align-items-center"
            >
              <b-button
                variant="blind-dark"
                title="Sort by name"
                class="font-weight-500"
                @click="sortResultsBy('name')"
              >
                {{ translate({ path: 'GLOBAL.NAME' }) }}&nbsp;
                <icon
                  v-if="sortProp === 'name'"
                  :icon="sortAscending === true ? 'sort-up' : 'sort-down'"
                />
                <icon
                  v-else
                  icon="sort"
                />
              </b-button>
            </div>
            <IndexOptionsHeadItem
              v-for="title in columnsToShow"
              :key="title"
              :active-filters.sync="activeFilters"
              :title="title"
              :sort-ascending="sortAscending ?? false"
              :sorting="sortProp === title"
              @sort="sortResultsBy(title)"
            />

            <div
              v-for="n in numPaddedColumns"
              :key="n"
            />
            <div class="d-flex align-items-center justify-content-end">
              <b-dropdown
                v-if="metricColumnDropdownOptions.length"
                size="sm"
                no-caret
                variant="blind-dark"
                toggle-class="squared-center-fa-btn"
                offset="1"
                right
              >
                <template #button-content>
                  <icon
                    icon="cog"
                    fixed-width
                  />
                </template>
                <b-dropdown-form
                  v-if="dataset.length"
                  form-class="px-2pt5"
                >
                  <template v-for="itemProperty of metricColumnDropdownOptions">
                    <b-form-checkbox
                      :key="itemProperty"
                      v-model="userSelectedColumns[tabModel]"
                      :value="itemProperty"
                      :disabled="
                        !columnsToShow.includes(itemProperty) && columnsToShow.length === maxResultsDisplayedProps
                      "
                      class="py-0 text-nowrap"
                      :class="{
                        disabled:
                          !columnsToShow.includes(itemProperty) && columnsToShow.length === maxResultsDisplayedProps,
                      }"
                      @click.stop
                    >
                      {{ translate({ path: 'GLOBAL.DATABASE_TRANSLATOR', item: translator[itemProperty] }) }}
                    </b-form-checkbox>
                  </template>
                </b-dropdown-form>
              </b-dropdown>
            </div>
          </div>
        </b-card>
        <div>
          <RecycleScroller
            v-show="filteredOptions.length"
            ref="virtualList"
            :items="filteredOptions"
            :item-size="29"
            class="scrollable"
            key-field="virtualKey"
            :style="{
              height: tableHeight,
              'overflow-y': 'auto',
            }"
          >
            <template #default="{ item }">
              <IndexSearchBoxScrollItem
                :is-item-enabled-fn="isItemEnabledFn"
                :is-item-selected-fn="isItemSelectedFn"
                :format-index-name="formatIndexName"
                :columns-to-show="columnsToShow"
                :format-prop-display="formatPropDisplay"
                :toggle-items="toggleItems"
                :settings-button-width="settingsButtonWidth"
                :dsb-name-column-width="dsbNameColumnWidth"
                :options="optionsToUse"
                :source="item"
                class="w-100 py-0p5 hover-grey"
              />
            </template>
          </RecycleScroller>

          <div
            v-if="filteredOptions.length === 0"
            class="text-center py-4"
            :style="{
              height: tableHeight,
            }"
          >
            <em>{{ translate({ path: 'VIRTUAL_LIST.NO_RESULTS' }) }}</em>
          </div>
        </div>
      </div>
    </component>
  </b-overlay>
</template>

<script lang="ts">
import { ATTRIBUTE_DATABASE_NAME, ATTRIBUTE_PROPER_NAME } from '@/types/strategy';
import { cloneDeep, debounce, isEqual } from 'lodash';
import { sortBenchmarks, sortSignals, sortStrategies, translateKnownStrategyProperty } from '@/utils/strategy';
import { RecycleScroller } from 'vue-virtual-scroller';
import IndexSearchBoxScrollItem from './IndexSearchBoxScrollItem.vue';
import { DataTypes, normalizePortfolioType, normalizeType } from '@/types/setting';
import { databaseTranslator } from '@/utils/metrics';
import { DSBOptions } from '@/types/DSBOptions';
import { DEFAULT_OPTIONS } from '@/constants/DSBOptions';
import {
  computed,
  ComputedRef,
  defineComponent,
  nextTick,
  onMounted,
  PropType,
  ref,
  Ref,
  toRef,
  watch,
  watchEffect,
} from 'vue';
import { useSignals } from '@/composables/queries/useAdminStrategyManagement';
import { useScreenSizes } from '@/composables/useScreenSizes';
import { BFormCheckbox } from 'bootstrap-vue';
import { IFilter } from '@/types/IFilter';
import useTranslation from '@/composables/useTranslation';
import { templateRef, useVModel } from '@vueuse/core';
import { IPortfolioSubset, ISignal, IStrategySubset, SupportedColumn } from '@/types/IndexOptionsTable';
import IndexOptionsHeadItem from '@/components/index-search-box/IndexOptionsHeadItem.vue';
import useDSBColumns from '@/composables/storage/useDSBColumns';
import { VQQueryOptions } from '@/types/VueQueryTypes';
import { Region } from '@/constants/Region';
import { Currency } from '@/constants/Currency';
import { chainEnabled } from '@/utils/queries';
import useAppMetrics from '@/composables/useAppMetrics';
import { ACCEPTED_TRACKED_TITLES } from '@/types/analytics';
import {
  useAllStrategiesByCode,
  useAllPortfoliosBySlug,
  useStrategyByType,
  usePortfolioByType,
} from '@/composables/queries/useDataDiscovery';
import useEnv from '@/composables/useEnv';
import { useIndexUniqueIdentifier } from '@/composables/useCorrectIdentifier';
import { AnalyticsTrackTypeConstants } from '@/types/AnalyticsTrackTypeConstants';
import { useFeatureFlag } from '@/composables/useFeatureFlag';
import { useStrategyTicker } from '@/composables/useStrategyTicker';
import { sortPortfolios } from '@/utils/portfolio';

function useOptions(options: Ref<DSBOptions>): Ref<Required<DSBOptions>> {
  const optionsToUse = computed((): Required<DSBOptions> => {
    const base = {
      ...DEFAULT_OPTIONS,
      ...options.value,
    };

    /**
     * If you cannot select multiple, then allow 2 so that not all
     * options are disabled if you have one selected. But when you click on one,
     * then the current value of privateSelected is replaced
     */
    if (!base.selectMultiple) {
      base.numMaxStrategiesSelectable = 2;
    }

    return base;
  });

  return optionsToUse;
}

/**
 * We pass in all the Data Type queries here so that we can display the tabs which the database has or the user has permission to see
 * If there is no data returned by the API, the tab will not be visible on the DSB
 * On the core platform for Private Tracks, we will always show the Private Track tab even if its empty
 * On the HSBC Environment, we don't show the Private Track tab even if its empty
 * @param options DSB Options
 * @param tabModel Current Tab selected by the user
 * @param vqOptions vue-query options to pass to the queries
 * @param shouldDisplayStock Computed Ref to check if the stocks tab should be displayed
 */
function useTab(
  options: Ref<Required<DSBOptions>>,
  tabModel: Ref<DataTypes>,
  vqOptions: VQQueryOptions = {},
  shouldDisplayStock: ComputedRef<boolean>,
  shouldShowOnlyStrategies: ComputedRef<boolean>,
) {
  const vqOptionsStrategy = { ...vqOptions };

  // On the HSBC environment, we don't want to enable other types except strategy
  const { isHSBCEnvironment } = useEnv();
  vqOptions = { ...vqOptions, enabled: chainEnabled(vqOptions.enabled, !isHSBCEnvironment) };

  const plainStrategies = useStrategyByType(DataTypes.STRATEGY, vqOptionsStrategy);
  const privateTracks = useStrategyByType(DataTypes.PRIVATE_TRACK, vqOptions);
  const privateFunds = useStrategyByType(DataTypes.FUND, vqOptions);
  const thematics = useStrategyByType(DataTypes.THEMATIC, vqOptions);
  const benchmarks = useStrategyByType(DataTypes.BENCHMARK, vqOptions);
  const pureFactors = useStrategyByType(DataTypes.PURE_FACTOR, vqOptions);
  const marketData = useStrategyByType(DataTypes.MARKET_DATA, vqOptions);
  const stocks = useStrategyByType(DataTypes.STOCK, vqOptions);

  const normalPortfolios = usePortfolioByType(DataTypes.PORTFOLIO, vqOptions);
  const benchmarkPortfolios = usePortfolioByType(DataTypes.BENCHMARK, vqOptions);

  const signals = useSignals({
    ...vqOptions,
    enabled: chainEnabled(
      vqOptions.enabled,
      computed(() => options.value.shouldShowSignal),
    ),
  });

  const isLoading = computed<boolean>(() =>
    [
      plainStrategies,
      privateTracks,
      privateFunds,
      thematics,
      benchmarks,
      pureFactors,
      marketData,
      stocks,
      normalPortfolios,
      benchmarkPortfolios,
    ].some((o) => o.isLoading.value),
  );

  /**
   * Irritatingly, if these tabs load BEFORE the data is there, then when we
   * push tabs into the return value, BootstrapVue automatically selects that
   * tab as the selected tab. So if data is loading, then we return an empty array.
   */
  const tabsToShow = computed<DataTypes[]>(() => {
    const retval: DataTypes[] = [];

    if (isLoading.value) return retval;

    // show ONLY stocks when it's an equity portfolio and the Add Strategy Modal is triggered from the PortfolioWeightToolbar
    if (stocks.data.value?.length && shouldDisplayStock.value) {
      return [DataTypes.STOCK];
    }

    if (plainStrategies.data.value?.length && shouldShowOnlyStrategies.value) {
      return [DataTypes.STRATEGY];
    }

    if (benchmarks.data.value?.length || (options.value.shouldShowPortfolio && benchmarkPortfolios.data.value?.length))
      retval.push(DataTypes.BENCHMARK);
    if (pureFactors.data.value?.length) retval.push(DataTypes.PURE_FACTOR);
    if (plainStrategies.data.value?.length) retval.push(DataTypes.STRATEGY);
    if (thematics.data.value?.length) retval.push(DataTypes.THEMATIC);

    // always show Private Track tab so that
    // users know they can add them here
    if (!isHSBCEnvironment) retval.push(DataTypes.PRIVATE_TRACK);

    if (privateFunds.data.value?.length) {
      retval.push(DataTypes.FUND);
    }

    if (marketData.data.value?.length) {
      retval.push(DataTypes.MARKET_DATA);
    }

    if (options.value.shouldShowPortfolio) {
      // show Portfolio tab even if empty so that
      // users know they can add them here
      retval.push(DataTypes.PORTFOLIO);
    }

    if (options.value.shouldShowSignal && signals.data.value?.length) {
      retval.push(DataTypes.SIGNAL);
    }

    return retval;
  });

  const currentTabIndex = computed<number>(() => {
    return tabsToShow.value.indexOf(tabModel.value);
  });

  const defaultTab = computed<DataTypes>(() => {
    if (isHSBCEnvironment) return DataTypes.STRATEGY;

    if (benchmarks.data.value?.length) {
      return DataTypes.BENCHMARK;
    }
    if (plainStrategies.data.value?.length) {
      return DataTypes.STRATEGY;
    }
    return DataTypes.PRIVATE_TRACK;
  });

  const { getCompositeTicker } = useStrategyTicker();

  const dataset = computed((): ReadonlyArray<IStrategySubset | IPortfolioSubset | ISignal> => {
    switch (tabModel.value) {
      case DataTypes.PURE_FACTOR:
        return sortStrategies(
          (pureFactors.data.value ?? []).map((d) => ({
            ...d,
            shortName: d.shortName.replace(/^(PLB Pure Factor |Premialab Pure Factor )/, ''),
          })),
        );
      case DataTypes.STRATEGY:
        return sortStrategies(plainStrategies.data.value ?? []);
      case DataTypes.PRIVATE_TRACK:
        return sortStrategies(privateTracks.data.value ?? []);
      case DataTypes.FUND:
        return sortStrategies(privateFunds.data.value ?? []);
      case DataTypes.THEMATIC:
        return sortStrategies(thematics.data.value ?? []);
      case DataTypes.PORTFOLIO:
        return sortPortfolios(normalPortfolios.data.value ?? []);
      case DataTypes.SIGNAL:
        return sortSignals(signals.data.value ?? []);
      case DataTypes.MARKET_DATA:
        return sortStrategies(marketData.data.value ?? []);
      case DataTypes.STOCK: {
        return sortStrategies(stocks.data.value ?? []).map((stock) => {
          const compositeTicker = getCompositeTicker(stock.ticker);
          return { ...stock, ticker: compositeTicker ?? undefined };
        });
      }
      default:
        return isHSBCEnvironment
          ? sortStrategies(plainStrategies.data.value ?? [])
          : sortBenchmarks([...(benchmarks.data.value ?? []), ...(benchmarkPortfolios.data.value ?? [])]);
    }
  });

  return {
    isLoading,
    tabsToShow,
    defaultTab,
    currentTabIndex,
    dataset,
  };
}

const { translate } = useTranslation();

export default defineComponent({
  name: 'IndexOptionsTable',
  components: {
    IndexSearchBoxScrollItem,
    RecycleScroller,
    BFormCheckbox,
    IndexOptionsHeadItem,
  },
  props: {
    query: {
      type: String,
      required: true,
    },
    /**
     * Indicate if the table is shown and rendered. This component will watch on this property and reset
     * the component state when this is changed.
     *
     * Other than the resetting, this props does nothing to this component.
     */
    shown: {
      type: Boolean,
      required: false,
      default: false,
    },
    selected: {
      /**
       * You must cast the entire array in the PropType to avoid an error
       */
      type: [Array, String] as PropType<string[] | string>,
      required: true,
    },
    /**
     * Supply a variable to this prop to keep track of whether the selected item is of type 'portfolio' or 'track'
     *
     * This will only be updated if the component is in 'single' mode (i.e., only one item may be selected)
     */
    selectedType: {
      type: String as PropType<AnalyticsTrackTypeConstants>,
      required: false,
    },
    /**
     * Supply a variable to this prop to keep track of the selected item's currency
     *
     * This will only be updated if the component is in 'single' mode (i.e., only one item may be selected)
     */
    selectedCurrency: {
      type: String as PropType<Currency>,
      required: false,
    },
    /**
     * Currently active tab/shown data type. Available for `.sync` binding.
     */
    tab: {
      type: String as PropType<DataTypes>,
      required: false,
      default: DataTypes.STRATEGY,
    },
    disabledCodes: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
    options: {
      type: Object as PropType<DSBOptions>,
      required: false,
      default: () => DEFAULT_OPTIONS,
    },
    isVueQueryEnabled: {
      type: Boolean,
      required: true,
    },
    componentClasses: {
      type: String,
      required: false,
    },
    shouldShowStocks: {
      type: Boolean,
      required: false,
    },
    /**
     * When true, the DSB shows only strategies and no other tabs
     */
    shouldShowOnlyStrategies: {
      type: Boolean,
      required: false,
    },
  },
  emits: {
    /**
     * Fires when the loading state of this component has changed.
     * This will fire when this component is mounted. Parent subscribe to the loading status if interested
     * (using `@loading-changed="loading = $event"`).
     *
     * Think of this as a `.sync`/`v-model` binding, but instead of 2-way/parent-to-child, this is child-to-parent.
     *
     * Note: 🤔 I know This is a weird API, but IDK what's a better solution
     */
    'loading-changed': (loading: boolean) => typeof loading === 'boolean',
    'update:tab': (_tab: DataTypes) => true,
    'update:selected': null,
    'update:selectedType': null,
    'update:selectedCurrency': null,
  },
  setup(props, { emit }) {
    const { startTimer, endTimer, trackButtonClick, track } = useAppMetrics();
    const tabSwitchId = ref<number | null>(null);

    const optionsToUse = useOptions(toRef(props, 'options'));

    const tabModel = useVModel(props, 'tab', emit, { passive: true });
    const indexUniqueIdentifier = useIndexUniqueIdentifier();

    const shouldShowStock = computed(() => props.shouldShowStocks ?? false);

    const shouldShowOnlyStrategies = computed(() => props.shouldShowOnlyStrategies ?? false);

    const { isLoading, tabsToShow, currentTabIndex, dataset, defaultTab } = useTab(
      optionsToUse,
      tabModel,
      {
        enabled: toRef(props, 'isVueQueryEnabled'),
      },
      shouldShowStock,
      shouldShowOnlyStrategies,
    );

    watchEffect(() => emit('loading-changed', isLoading.value));

    // This will be populated and synced back by <IndexOptionsHeadItem />
    const activeFilters = ref<IFilter<string>[]>([]);

    const { userSelectedColumns, columnsToShow, filteredOptions } = useDSBColumns(tabModel, activeFilters);

    const foundNoResults = ref(false);

    const sortAscending = ref<boolean | null>(true);

    const sortProp = ref<SupportedColumn | 'name' | null>(null);

    const settingsButtonWidth = 28.5;

    const dsbNameColumnWidth = 247;

    const areSomeStrategiesSelected = ref(false);

    const areMaxStrategiesSelected = ref(false);

    const translator = databaseTranslator;

    const maxResultsDisplayedProps = 3;

    const selectedModel = useVModel(props, 'selected', emit);
    const selectedTypeModel = useVModel(props, 'selectedType', emit);
    const selectedCurrencyModel = useVModel(props, 'selectedCurrency', emit);
    const totalSelectedItems = computed<number>(() => {
      if (Array.isArray(selectedModel.value)) return selectedModel.value.length;
      if (selectedModel.value) return 1;
      return 0;
    });

    const allStrategiesByCode = useAllStrategiesByCode();

    const allPortfolioTreesBySlug = useAllPortfoliosBySlug();

    const numPaddedColumns = computed(() => {
      if (columnsToShow.value.length >= 3) return 0;
      return 3 - columnsToShow.value.length;
    });

    const { scrollbarWidth } = useScreenSizes();

    const headerRowStyle = computed(() => {
      let columnTemplate =
        scrollbarWidth.value !== 0
          ? '36px 5fr 2fr 2fr 2fr ' + (scrollbarWidth.value + settingsButtonWidth) + 'px'
          : '36px 5fr 2fr 2fr 2fr min-content';

      if (columnsToShow.value.length === 2) {
        columnTemplate =
          scrollbarWidth.value !== 0
            ? '36px 7fr 5fr 5fr 1fr ' + (scrollbarWidth.value + settingsButtonWidth) + 'px'
            : '36px 7fr 5fr 5fr 1fr min-content';
      }

      return {
        display: 'grid',
        'grid-template-columns': columnTemplate,
      };
    });

    const resetSort = (): void => {
      sortAscending.value = null;
      sortProp.value = null;
    };
    const filterOptions = (): void => {
      const lowercaseQuery = props.query.toLowerCase();

      resetSort();

      if (!dataset.value) return;

      const prefilteredData = cloneDeep(dataset.value).filter((datum) => {
        let shouldShow = true;

        // Remove if datum is the same as the indexUniqueIdentifier
        // No need to add/compare to self
        const itemIdentifier = 'slug' in datum ? datum.slug : datum.code;
        if (itemIdentifier === indexUniqueIdentifier.value) shouldShow = false;

        activeFilters.value.forEach((filter) => {
          shouldShow = shouldShow && datum[filter.property as keyof typeof datum] === filter.value;
        });

        Object.assign(datum, { virtualKey: 'slug' in datum ? datum.portfolioId : datum.id });

        return shouldShow;
      });

      if (prefilteredData.length) {
        const allObjectProps = Object.keys(prefilteredData[0]);
        const allShownProps = Object.keys(databaseTranslator);
        const hiddenProps = [
          'performanceFee',
          'managementFee',
          'dividendFrequency',
          'benchmarks',
          'ucits',
          'managerNames',
          'domicile',
          'legalStructure',
        ];
        const propsToSearch = allObjectProps.filter(
          (prop) => allShownProps.includes(prop) && !hiddenProps.includes(prop),
        );

        if (tabModel.value === 'PORTFOLIO') {
          propsToSearch.push('name');
        } else propsToSearch.push(ATTRIBUTE_DATABASE_NAME.ShortName);

        if (tabModel.value === DataTypes.BENCHMARK) {
          propsToSearch.push(ATTRIBUTE_DATABASE_NAME.Code);
        }

        if (lowercaseQuery === '' || lowercaseQuery === null) {
          filteredOptions.value = prefilteredData;
        } else {
          const queryTerms = lowercaseQuery.split(' ');

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const sortByRelevancy = (a: any, b: any) => {
            if (a.searchRelevancy > b.searchRelevancy) {
              return -1;
            }
            if (a.searchRelevancy < b.searchRelevancy) {
              return 1;
            }
            return 0;
          };

          filteredOptions.value = prefilteredData
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .filter((option: any) => {
              option.searchRelevancy = 0;

              let arrayOfProps: string[] = [];
              for (let i = 0; i < propsToSearch.length; i++) {
                if (
                  (option.name && option.name.toLowerCase().includes(lowercaseQuery)) ||
                  (option.shortName && option.shortName.toLowerCase().includes(lowercaseQuery)) ||
                  (option.title && option.title.toLowerCase().includes(lowercaseQuery))
                ) {
                  option.searchRelevancy += 10;
                  return true;
                }

                const prop = propsToSearch[i];
                const opt = option[prop];

                if (typeof opt === 'string' && opt !== '') {
                  arrayOfProps = arrayOfProps.concat(option[prop].split(' '));
                } else if (Array.isArray(opt)) {
                  arrayOfProps.push(...opt);
                }
              }

              for (let j = 0; j < arrayOfProps.length; j++) {
                const propTerm = arrayOfProps[j].toLowerCase();

                for (let k = 0; k < queryTerms.length; k++) {
                  const queryTerm = queryTerms[k].toLowerCase();

                  if (propTerm === queryTerm) {
                    option.searchRelevancy += 1;
                  }
                }
              }
              if (option.searchRelevancy > 0) return true;
              return false;
            })
            .sort(sortByRelevancy);
        }

        if (filteredOptions.value.length === 0) foundNoResults.value = true;
      } else {
        filteredOptions.value = [];

        foundNoResults.value = true;
      }
      if (tabSwitchId.value !== null) {
        const tabSwitch = endTimer(tabSwitchId.value, 'DSB tab switch');
        if (tabSwitch) {
          trackButtonClick(`DSB Tab`, {
            timeElapsed: tabSwitch.timeElapsed,
            tabName: tabModel.value,
            numResults: filteredOptions.value.length,
          });
        }
      }
    };
    const debouncedFilterOptions = debounce(filterOptions, 200);

    watch(
      dataset,
      async () => {
        await nextTick();
        filterOptions();
      },
      { immediate: true },
    );

    watch([activeFilters, () => props.query], ([newFilters, newQuery], [oldFilters, oldQuery]) => {
      if (isEqual(newFilters, oldFilters) && newQuery === oldQuery) return;
      debouncedFilterOptions();
    });

    const sortMetricColumnDropdownOptions = (a: string, b: string): number => {
      if (databaseTranslator[a] > databaseTranslator[b]) return 1;
      if (databaseTranslator[a] < databaseTranslator[b]) return -1;
      return 0;
    };

    const metricColumnDropdownOptions = computed(() => {
      const retval: {
        [key in SupportedColumn]?: ATTRIBUTE_PROPER_NAME;
      } = {};

      if (tabModel.value === DataTypes.BENCHMARK) {
        retval[ATTRIBUTE_DATABASE_NAME.AssetClass] = ATTRIBUTE_PROPER_NAME.AssetClass;
        retval[ATTRIBUTE_DATABASE_NAME.Currency] = ATTRIBUTE_PROPER_NAME.Currency;
        retval[ATTRIBUTE_DATABASE_NAME.HistoryStartDate] = ATTRIBUTE_PROPER_NAME.HistoryStartDate;
        retval[ATTRIBUTE_DATABASE_NAME.Code] = ATTRIBUTE_PROPER_NAME.Code;
        retval[ATTRIBUTE_DATABASE_NAME.ReturnType] = ATTRIBUTE_PROPER_NAME.ReturnType;
      } else if (tabModel.value === DataTypes.PURE_FACTOR) {
        retval[ATTRIBUTE_DATABASE_NAME.AssetClass] = ATTRIBUTE_PROPER_NAME.AssetClass;
        retval[ATTRIBUTE_DATABASE_NAME.Currency] = ATTRIBUTE_PROPER_NAME.Currency;
        retval[ATTRIBUTE_DATABASE_NAME.Factor] = ATTRIBUTE_PROPER_NAME.Factor;
        retval[ATTRIBUTE_DATABASE_NAME.HistoryStartDate] = ATTRIBUTE_PROPER_NAME.HistoryStartDate;
        retval[ATTRIBUTE_DATABASE_NAME.Code] = ATTRIBUTE_PROPER_NAME.Code;
        retval[ATTRIBUTE_DATABASE_NAME.InceptionDate] = ATTRIBUTE_PROPER_NAME.InceptionDate;
        retval[ATTRIBUTE_DATABASE_NAME.Style] = ATTRIBUTE_PROPER_NAME.Style;
      } else if (tabModel.value === DataTypes.STRATEGY || tabModel.value === DataTypes.THEMATIC) {
        retval[ATTRIBUTE_DATABASE_NAME.AssetClass] = ATTRIBUTE_PROPER_NAME.AssetClass;
        retval[ATTRIBUTE_DATABASE_NAME.Currency] = ATTRIBUTE_PROPER_NAME.Currency;
        retval[ATTRIBUTE_DATABASE_NAME.Factor] = ATTRIBUTE_PROPER_NAME.Factor;
        retval[ATTRIBUTE_DATABASE_NAME.HistoryStartDate] = ATTRIBUTE_PROPER_NAME.HistoryStartDate;
        retval[ATTRIBUTE_DATABASE_NAME.Code] = ATTRIBUTE_PROPER_NAME.Code;
        retval[ATTRIBUTE_DATABASE_NAME.InceptionDate] = ATTRIBUTE_PROPER_NAME.InceptionDate;
        retval[ATTRIBUTE_DATABASE_NAME.ReturnCategory] = ATTRIBUTE_PROPER_NAME.ReturnCategory;
        retval[ATTRIBUTE_DATABASE_NAME.ReturnType] = ATTRIBUTE_PROPER_NAME.ReturnType;
        retval[ATTRIBUTE_DATABASE_NAME.Style] = ATTRIBUTE_PROPER_NAME.Style;
        retval[ATTRIBUTE_DATABASE_NAME.VolTarget] = ATTRIBUTE_PROPER_NAME.VolTarget;
      } else if (tabModel.value === DataTypes.PRIVATE_TRACK || tabModel.value === DataTypes.FUND) {
        retval[ATTRIBUTE_DATABASE_NAME.AssetClass] = ATTRIBUTE_PROPER_NAME.AssetClass;
        retval[ATTRIBUTE_DATABASE_NAME.Currency] = ATTRIBUTE_PROPER_NAME.Currency;
        retval[ATTRIBUTE_DATABASE_NAME.Factor] = ATTRIBUTE_PROPER_NAME.Factor;
        retval[ATTRIBUTE_DATABASE_NAME.HistoryStartDate] = ATTRIBUTE_PROPER_NAME.HistoryStartDate;
        retval[ATTRIBUTE_DATABASE_NAME.InceptionDate] = ATTRIBUTE_PROPER_NAME.InceptionDate;
        retval[ATTRIBUTE_DATABASE_NAME.ReturnCategory] = ATTRIBUTE_PROPER_NAME.ReturnCategory;
        retval[ATTRIBUTE_DATABASE_NAME.ReturnType] = ATTRIBUTE_PROPER_NAME.ReturnType;
        retval[ATTRIBUTE_DATABASE_NAME.Style] = ATTRIBUTE_PROPER_NAME.Style;
      }
      if (tabModel.value === DataTypes.PORTFOLIO) {
        retval[ATTRIBUTE_DATABASE_NAME.AssetClass] = ATTRIBUTE_PROPER_NAME.AssetClass;
        retval[ATTRIBUTE_DATABASE_NAME.Factor] = ATTRIBUTE_PROPER_NAME.Factor;
      }
      if (tabModel.value === DataTypes.STOCK) {
        retval[ATTRIBUTE_DATABASE_NAME.Currency] = ATTRIBUTE_PROPER_NAME.Currency;
        // Temporary hide this as we only want to show equity with return type price return
        // retval[ATTRIBUTE_DATABASE_NAME.ReturnType] = ATTRIBUTE_PROPER_NAME.ReturnType;
        retval[ATTRIBUTE_DATABASE_NAME.Factor] = ATTRIBUTE_PROPER_NAME.Factor;
        retval[ATTRIBUTE_DATABASE_NAME.Ticker] = ATTRIBUTE_PROPER_NAME.Ticker;
        retval[ATTRIBUTE_DATABASE_NAME.ISIN] = ATTRIBUTE_PROPER_NAME.ISIN;
        retval[ATTRIBUTE_DATABASE_NAME.SEDOL] = ATTRIBUTE_PROPER_NAME.SEDOL;
        retval[ATTRIBUTE_DATABASE_NAME.Sector] = ATTRIBUTE_PROPER_NAME.Sector;
      }

      if (tabModel.value !== DataTypes.PORTFOLIO) {
        retval[ATTRIBUTE_DATABASE_NAME.Ticker] = ATTRIBUTE_PROPER_NAME.Ticker;
      }

      // Shared attribute across all datatype
      retval[ATTRIBUTE_DATABASE_NAME.Region] = ATTRIBUTE_PROPER_NAME.Region;

      return (Object.keys(retval) as SupportedColumn[]).sort(sortMetricColumnDropdownOptions);
    });

    const formatIndexName = (strategyOrPortfolio: IStrategySubset | IPortfolioSubset | ISignal): string => {
      if ('slug' in strategyOrPortfolio) return strategyOrPortfolio.name;
      return strategyOrPortfolio.shortName;
    };
    const sortResultsBy = (prop: SupportedColumn | 'name'): void => {
      const oldProp = sortProp.value;
      sortProp.value = prop;

      // If the sortBy column is a date column (historyStartDate and inceptionDate for this case), sort in descending order on the first click
      if (sortAscending.value === null || oldProp !== prop) {
        sortAscending.value =
          sortProp.value === 'historyStartDate' || sortProp.value === 'inceptionDate' ? false : true;
      } else sortAscending.value = !sortAscending.value;

      const sortByProp = (
        a: IStrategySubset | IPortfolioSubset | ISignal,
        b: IStrategySubset | IPortfolioSubset | ISignal,
      ) => {
        if (prop === 'name') {
          if (formatIndexName(a) > formatIndexName(b)) {
            return sortAscending.value ? 1 : -1;
          }
          if (formatIndexName(a) < formatIndexName(b)) {
            return sortAscending.value ? -1 : 1;
          }
          return 0;
        }

        // Todo: Remove cast when we upgrade to TypeScript 4.9.
        const aProp = prop in a ? a[prop as keyof typeof b] : null;
        const bProp = prop in b ? b[prop as keyof typeof b] : null;

        if ((aProp && !bProp) || (aProp && bProp && aProp > bProp)) {
          return sortAscending.value ? 1 : -1;
        }
        if ((!aProp && bProp) || (aProp && bProp && aProp < bProp)) {
          return sortAscending.value ? -1 : 1;
        }

        return 0;
      };

      if (prop === 'region') {
        filteredOptions.value = filteredOptions.value.map((obj) => {
          if ('region' in obj && obj.region === null) {
            // The default region is 'Global'
            return { ...obj, region: Region.GLOBAL };
          }
          return obj;
        });
      }

      if (prop === 'currency') {
        filteredOptions.value = filteredOptions.value.map((obj) => {
          if ('currency' in obj && obj.currency === null) {
            // The default currency is 'USD'
            return { ...obj, currency: Currency.USD };
          }
          return obj;
        });
      }

      filteredOptions.value = filteredOptions.value.slice().sort(sortByProp);
    };

    const tabNames = computed(() => {
      const retval = {} as Record<DataTypes, string>;
      for (const value of Object.values(DataTypes)) {
        retval[value as DataTypes] = translate({ path: 'GLOBAL.STRATEGY_TYPE_NAME', item: value, pluralIndex: 2 });
      }
      return retval;
    });

    const formatPropDisplay = (property: string, prop: string | null | undefined): string => {
      if (prop == null) {
        return '-';
      }

      const translation = translateKnownStrategyProperty(property, prop);
      if (translation) {
        return translation;
      }

      let retval = prop;

      switch (prop) {
        case 'Risk':
          retval = 'Risk Parity';
          break;

        case 'erc':
          retval = 'ERC';
          break;

        default:
          break;
      }

      return retval;
    };

    const numSelectedInSection = computed(() => {
      if (typeof selectedModel.value === 'string') return selectedModel.value !== '' ? 1 : 0;
      return selectedModel.value.filter((id) => {
        const strategy = allStrategiesByCode.data.value?.get(id);
        if (strategy) {
          return normalizeType(strategy) === tabModel.value;
        }
        const portfolio = allPortfolioTreesBySlug.data.value?.get(id);
        if (portfolio) {
          return normalizePortfolioType(portfolio) === tabModel.value;
        }
        return false;
      }).length;
    });

    const updateCheckboxes = async (): Promise<void> => {
      areMaxStrategiesSelected.value =
        numSelectedInSection.value > 0 &&
        optionsToUse.value.numMaxStrategiesSelectable !== -1 &&
        (totalSelectedItems.value >= optionsToUse.value.numMaxStrategiesSelectable ||
          filteredOptions.value.length === numSelectedInSection.value);
      await nextTick();

      areSomeStrategiesSelected.value = numSelectedInSection.value > 0 && !areMaxStrategiesSelected.value;
    };
    watch(selectedModel, () => {
      setTimeout(() => updateCheckboxes());
    });
    const selectSection = (newDataType: DataTypes): void => {
      tabSwitchId.value = startTimer();
      tabModel.value = newDataType;
      updateCheckboxes();
      activeFilters.value = [];
    };

    const canAddItem = computed((): boolean => {
      return (
        optionsToUse.value.numMaxStrategiesSelectable === -1 ||
        totalSelectedItems.value < optionsToUse.value.numMaxStrategiesSelectable
      );
    });

    const isItemEnabledFn = (suggestionItem: IStrategySubset | IPortfolioSubset | ISignal): boolean => {
      if (!canAddItem.value) {
        return selectedModel.value.includes('slug' in suggestionItem ? suggestionItem.slug : suggestionItem.code);
      }
      if (
        !optionsToUse.value.canRemovePreviouslySelected &&
        props.disabledCodes.find((x) =>
          'slug' in suggestionItem ? x === suggestionItem.slug : x === suggestionItem.code,
        )
      ) {
        return false;
      }

      return true;
    };

    const toggleItems = (
      strategyOrPortfolios:
        | (IStrategySubset | IPortfolioSubset | ISignal)[]
        | IStrategySubset
        | IPortfolioSubset
        | ISignal,
    ): void => {
      if (Array.isArray(strategyOrPortfolios)) {
        const idsToUse = strategyOrPortfolios.map((x) => {
          return 'slug' in x ? x.slug : x.code;
        });

        const copy = [...selectedModel.value];
        for (const id of idsToUse) {
          const index = selectedModel.value.indexOf(id);

          if (index < 0 && canAddItem.value) {
            copy.push(id);
          } else copy.splice(index, 1);
        }
        selectedModel.value = copy;
        return;
      }

      if ('slug' in strategyOrPortfolios) {
        selectedModel.value = strategyOrPortfolios.slug;
        selectedTypeModel.value = AnalyticsTrackTypeConstants.PORTFOLIO;
      } else {
        selectedModel.value = strategyOrPortfolios.code;
        selectedTypeModel.value = AnalyticsTrackTypeConstants.TRACK;
        selectedCurrencyModel.value = Currency.USD;
      }

      if ('currency' in strategyOrPortfolios) {
        selectedCurrencyModel.value = strategyOrPortfolios.currency ?? Currency.USD;
      }
    };

    const allSelectedInCurrentTab = computed(() => {
      if (typeof selectedModel.value === 'string') return selectedModel.value;
      return selectedModel.value.filter((id) => {
        const strategy = allStrategiesByCode.data.value?.get(id);
        if (strategy) {
          return normalizeType(strategy) === tabModel.value;
        }
        const portfolio = allPortfolioTreesBySlug.data.value?.get(id);
        if (portfolio) {
          return normalizePortfolioType(portfolio) === tabModel.value;
        }
        return false;
      });
    });

    const toggleAllSelected = async (): Promise<void> => {
      /**
       * For selectMultiple false we don't show toggleAllSelected, so we just return
       */
      if (typeof selectedModel.value === 'string') return;

      // clicking sets areMaxStrategiesSelected to !areMaxStrategiesSelected
      // so we check for the newVal which is the variable itself now
      const newVal = areMaxStrategiesSelected.value;
      if (newVal === false) {
        selectedModel.value = selectedModel.value.filter((id) => !allSelectedInCurrentTab.value.includes(id));
        return;
      }

      const optionsToIterateOver = filteredOptions.value
        /**
         * Of the options shown in the table, remove the ones that are already selected
         * so that we don't try to select them again
         */
        .filter((datum) => {
          const idToUse = 'slug' in datum ? datum.slug : datum.code;
          return !allSelectedInCurrentTab.value.includes(idToUse);
        })
        /**
         * Next, take the total number of items we can select and subtract the
         * number of items already selected to get the max number of items
         * we can select in this group option
         */
        .slice(0, optionsToUse.value.numMaxStrategiesSelectable - totalSelectedItems.value);

      toggleItems(optionsToIterateOver);
    };

    const tableHeight = computed(() => `${optionsToUse.value.fullHeight - 90}px`);

    const isItemSelectedFn = (item: IStrategySubset | IPortfolioSubset | ISignal) => {
      const codeOrSlug = 'slug' in item ? item.slug : item.code;
      if (typeof selectedModel.value === 'string') return selectedModel.value === codeOrSlug;
      return selectedModel.value.includes(codeOrSlug);
    };

    // Reset scroll position when tab change
    const virtualList = templateRef<typeof RecycleScroller>('virtualList');
    const resetScroll = () => {
      virtualList.value?.scrollToItem(0);
    };
    watch(tabModel, () => {
      resetScroll();
    });

    watch(
      () => props.shown,
      () => {
        // Reset component state
        tabModel.value = defaultTab.value;
        activeFilters.value = [];
        resetScroll();
      },
    );

    const tableRenderId = startTimer();
    onMounted(() => {
      const { shouldShowStrategyAsDefaultIndexOptionsTable } = useFeatureFlag();
      if (shouldShowStrategyAsDefaultIndexOptionsTable.value) {
        tabModel.value = DataTypes.STRATEGY;
      }
      const mountTable = endTimer(tableRenderId, 'render IndexOptionsTable');
      if (mountTable) {
        track(ACCEPTED_TRACKED_TITLES.DSB_MOUNTED, {
          timeElapsed: mountTable.timeElapsed,
          url: window.location.pathname,
        });
      }
    });

    const { shouldShowPortfolioTabHSBC } = useFeatureFlag();

    return {
      allSelectedInCurrentTab,
      tabsToShow,
      tabNames,
      selectSection,
      toggleAllSelected,
      sortResultsBy,
      columnsToShow,
      sortAscending,
      translator,
      activeFilters,
      numPaddedColumns,
      dataset,
      metricColumnDropdownOptions,
      userSelectedColumns,
      filteredOptions,
      tabModel,
      maxResultsDisplayedProps,
      dsbNameColumnWidth,
      headerRowStyle,
      currentTabIndex,
      formatPropDisplay,
      isItemEnabledFn,
      sortProp,
      areMaxStrategiesSelected,
      areSomeStrategiesSelected,
      formatIndexName,
      toggleItems,
      settingsButtonWidth,
      isLoading,
      tableHeight,
      optionsToUse,
      numSelectedInSection,
      totalSelectedItems,
      canAddItem,
      isItemSelectedFn,
      translate,
      DataTypes,
      shouldShowPortfolioTabHSBC,
    };
  },
});
</script>
